// 
// _dropdown.scss
// 

.dropdown-toggle-split {
  &::before {
    content: "\F0140";
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
  }
}

.dropup {
  .dropdown-toggle-split {
    &::before {
      content: "\F0143";
      display: inline-block;
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: inherit;
      text-rendering: auto;
      line-height: inherit;
    }
  }
}

.dropright .dropdown-toggle-split {
  &::before {
    content: "\F0142";
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
  }
}

.dropdown-menu {
  &:focus-visible {
    outline: none !important;
  }
}


.dropdown-menu {
  box-shadow: $box-shadow-lg;
  animation-name: DropDownSlide;
  animation-duration: .3s;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;

  &.show {
    top: 100%!important;
  }
}

.dropdown-menu-end[style] {
  left: auto!important;
  right: 0!important;
}

.dropdown-menu[x-placement^=right],.dropdown-menu[x-placement^=top],.dropdown-menu[x-placement^=left]{
  top: auto !important;
  animation: none !important;
  transform: none !important;
}

@keyframes DropDownSlide {
  100% {
      transform: translateY(0)
  }
  0% {
      transform: translateY(10px)
  }
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider{
  border-top-color: $border-color;
}

// Dropdown Mega Menu

.dropdown-mega {
  position: static!important;
}

.dropdown-megamenu[style] {
  padding: 20px;
  left: 20px !important;
  right: 20px !important;
}

// Dropdown size

.dropdown-mega-menu-xl{
  width: 40rem;
}

.dropdown-mega-menu-lg{
  width: 26rem;
}